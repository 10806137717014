import api from './api.js';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Action() {
    let { id } = useParams();
    const go = async () => {
        const timeZoneOffset = new Date().getTimezoneOffset() / -60;
        const formattedOffset = (timeZoneOffset >= 0 ? "+" : "") + timeZoneOffset;

        // 创建表单数据
        const formData = new FormData();
        formData.append('Time-Zone', formattedOffset);

        // 发送表单数据
        const res = await api('POST', '/s/' + id, formData);
        // 判断返回的数据是否有res字段
        if (!res.res) {
            // 如果没有res字段，说明返回的数据不是预期的数据
            // 这里可以根据实际情况进行处理，比如跳转到其他页面
            return;
        } else {
            // 跳转到res
            window.location.href = res.res;
        }
        console.log(res);
    };

    useEffect(() => {
        go();
    }, []);

    return (
        <></>
    );
}

export default Action;