import React, { useEffect, useState } from 'react';
import api from './api';
import toast, { Toaster } from "react-hot-toast";

function App() {
    const [currentUrl] = useState(window.location.href.split("/").slice(0, 3).join("/"));
    const [list, setList] = useState([]);

    const getList = async () => {
        const res = await api('GET', '/api/list');
        setList(res);
    }

    const add = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        const res = await api('POST', '/api/add', JSON.stringify(data));
        const tips = edit === 0 ? '添加' : '编辑';
        if (res.res === "ok") {
            toast.success(tips + "成功");
            getList();
            setEdit(0);
        } else {
            toast.error(tips + "失败");
        }
    };

    const remove = async (id) => {
        const res = await api('GET', `/api/delete/${id}`, null);
        if (res.res === "ok") {
            toast.success("删除成功");
            getList();
        } else {
            toast.error("删除失败");
        }
    };
    const [countryList, setCountryList] = useState([]);
    const getCuntryList = async () => {
        const res = await api('GET', '/api/country');
        setCountryList(res);
    }
    const actionEdit = (item) => {
        setEdit(item.id);
        document.getElementById('name').value = item.name;
        document.getElementById('is_url').value = item.is_url;
        document.getElementById('no_url').value = item.no_url;
        document.getElementById('country').value = item.country;
        document.getElementById('device').value = item.device;
        document.getElementById('proxy').checked = item.proxy;
        document.getElementById('while_list').value = item.while_list;
        window.scrollTo(0, 0);
    }
    const [edit, setEdit] = useState(0);
    useEffect(() => {
        getList();
        getCuntryList();
    }, []);
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <Toaster />
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                        斗篷
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={add}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <input name='id' type="number" value={edit} hidden />
                        <div>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="备注 便于区分"
                            />
                        </div>
                        <div>
                            <input
                                id="is_url"
                                name="is_url"
                                type="text"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="目标跳转地址（A页）多个,分隔"
                            />
                        </div>
                        <div>
                            <input
                                id="no_url"
                                name="no_url"
                                type="text"
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="非目标跳转地址（B页） 不填写则不跳转"
                            />
                        </div>
                    </div>
                    <div className="mt-6">
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                            目标国家
                        </label>
                        <select
                            id="country"
                            name="country"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value="none">不限制</option>
                            {countryList.map((item, index) => (
                                <option key={index} value={item.country}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mt-6">
                        <label htmlFor="device" className="block text-sm font-medium text-gray-700">
                            目标设备
                        </label>
                        <select
                            id="device"
                            name="device"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value="none">不限制</option>
                            <option value="pc">电脑</option>
                            <option value="phone">手机</option>
                        </select>
                    </div>
                    <div className="mt-6 flex items-center">
                        <label htmlFor="proxy" className="block text-sm font-medium text-gray-700">
                            非代理
                        </label>
                        <input
                            id="proxy"
                            name="proxy"
                            type="checkbox"
                            className="ml-3 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <p className="ml-auto text-sm text-gray-500">选中后代理访客将跳转到B页</p>
                    </div>
                    <div className="mt-6">
                        <label htmlFor="while_list" className="block text-sm font-medium text-gray-700">
                            白名单
                        </label>
                        <input
                            id="while_list"
                            name="while_list"
                            type="text"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            placeholder="白名单，英文逗号分隔"
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {edit === 0 ? '添加' : '编辑'}
                        </button>
                        {edit !== 0 && (
                            <button
                                type="button"
                                onClick={() => setEdit(0)}
                                className="my-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                取消
                            </button>
                        )}
                    </div>

                </form>
                <div className="mt-8">
                    <h3 className="text-center text-xl leading-9 font-extrabold text-gray-900">
                        列表
                    </h3>
                    <ul className="space-y-4">
                        {list.map((item, index) => (
                            <li
                                key={index}
                                className="bg-white shadow overflow-hidden rounded-md px-4 py-4 sm:px-6 sm:py-4"
                            >
                                <div className="flex justify-between items-center">
                                    <div>
                                        <p className="text-lg font-medium text-gray-900">{item.name}</p>
                                        <p className="mt-2 text-sm font-medium text-gray-900">访问地址：{currentUrl}/to/{item.id}</p>
                                        <p className="mt-1 text-sm text-gray-500">目标跳转地址: {item.is_url}</p>
                                        <p className="mt-1 text-sm text-gray-500">非目标跳转地址: {item.no_url}</p>
                                        <p className="mt-1 text-sm text-gray-500">目标国家: {item.country === 'none' ? '不限制' : item.country}</p>
                                        <p className="mt-1 text-sm text-gray-500">目标设备: {item.device === 'none' ? '不限制' : (item.device === 'pc' ? '电脑' : '手机')}</p>
                                        <p className="mt-1 text-sm text-gray-500">非代理: {item.proxy ? '是' : '否'}</p>
                                        <p className="mt-1 text-sm text-gray-500">白名单: {item.while_list === '' ? '无白名单' : item.while_list}</p>
                                    </div>
                                    <div>
                                        <button
                                            className="text-red-600 hover:text-red-900"
                                            onClick={() => remove(item.id)}
                                        >
                                            删除
                                        </button>
                                        <button
                                            className="mx-2 text-red-600 hover:text-red-900"
                                            onClick={() => actionEdit(item)}
                                        >
                                            编辑
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default App;
