import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
    const token = localStorage.getItem('token');
    const expire = localStorage.getItem('expire');
    if (token && expire) {
        if (Date.now() < Number(expire)) {
            return children;
        } else {
            // token过期，清除localStorage并跳转到登录页
            localStorage.clear();
            return <Navigate to="/login" />;
        }
    } else {
        return <Navigate to="/login" />;
    }
}

export { PrivateRoute };
