// api.js

import axios from "axios";

const isDevelopment = process.env.REACT_APP_ENV === "development";
const baseUrl = isDevelopment ? "http://localhost:8081" : "";

const instance = axios.create({
    baseURL: baseUrl,
    timeout: 5000, // 设置请求超时时间
});

// 定义通用的请求函数
const request = async (method, url, data = null, headers = {}) => {
    try {
        // 检查localStorage中的token是否存在且未过期
        const token = localStorage.getItem('token');
        const expire = localStorage.getItem('expire');
        if (token && expire) {
            if (Date.now() < Number(expire)) {
                headers['Authorization'] = `Bearer ${token}`;
            } else {
                // token过期，清除localStorage并跳转到登录页
                localStorage.clear();
                window.location.href = '/login';
            }
        }
        const response = await instance({
            method,
            url,
            data,
            headers,
        });
        return response.data;
    } catch (error) {
        // 错误处理
        throw new Error(error.response.data.message || error.message);
    }
};

export default request;
