// App.js

import { Route, Routes } from "react-router-dom";
import Admin from "./admin";
import Action from "./action";
import Login from "./login";
import User from "./user";
import { PrivateRoute } from "./PrivateRoute.js";
function App() {
  return (
    <Routes>
      {/* 页面默认导航到 Home 组件(页面上显示 Home Compontent) */}
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Admin />
          </PrivateRoute>
        }
      />
      <Route
        path="/user"
        element={
          <PrivateRoute>
            <User />
          </PrivateRoute>
        }
      />
      <Route path="/to/:id" element={<Action />} />
    </Routes>
  );
}

export default App;