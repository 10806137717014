import React, { useEffect, useState } from 'react';
import api from './api';
import toast, { Toaster } from "react-hot-toast";

function App() {
    const [list, setList] = useState([]);

    const getList = async () => {
        const res = await api('GET', '/api/user/list');
        // 判断是否为空
        if (res.data === null) {
            setList([]);
            return;
        } else {
            setList(res.data);
        }
    }

    const add = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        const res = await api('POST', '/api/user/add', JSON.stringify(data));
        const tips = edit === 0 ? '添加' : '编辑';
        if (res.status) {
            toast.success(tips + "成功");
            getList();
            setEdit(0);
        } else {
            toast.error(tips + "失败");
        }
    };

    const remove = async (id) => {
        if (id === 1) {
            toast.error("不能删除管理员");
            return;
        }
        const res = await api('GET', `/api/user/delete/${id}`, null);
        if (res.res === "ok") {
            toast.success("删除成功");
            getList();
        } else {
            toast.error("删除失败");
        }
    };
    const actionEdit = (item) => {
        setEdit(item.id);
        document.getElementById('username').value = item.username;
        document.getElementById('password').value = item.password;
        window.scrollTo(0, 0);
    }
    const [edit, setEdit] = useState(0);
    useEffect(() => {
        getList();
    }, []);
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <Toaster />
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                        斗篷
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={add}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <input name='id' type="number" value={edit} hidden />
                        <div>
                            <input
                                id="username"
                                name="username"
                                type="text"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="用户名"
                            />
                        </div>
                        <div>
                            <input
                                id="password"
                                name="password"
                                type="text"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="密码"
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {edit === 0 ? '添加' : '编辑'}
                        </button>
                        {edit !== 0 && (
                            <button
                                type="button"
                                onClick={() => setEdit(0)}
                                className="my-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                取消
                            </button>
                        )}
                    </div>
                </form>
                <div className="mt-8">
                    <h3 className="text-center text-xl leading-9 font-extrabold text-gray-900">
                        列表
                    </h3>
                    <ul className="space-y-4">
                        {list.map((item, index) => (
                            <li
                                key={index}
                                className="bg-white shadow overflow-hidden rounded-md px-4 py-4 sm:px-6 sm:py-4"
                            >
                                <div className="flex justify-between items-center">
                                    <div>
                                        <p className="text-lg font-medium text-gray-900">{item.username}</p>
                                        <p className="text-lg font-medium text-gray-900">{item.password}</p>
                                    </div>
                                    <div>
                                        <button
                                            className="text-red-600 hover:text-red-900 mx-2"
                                            onClick={() => remove(item.id)}
                                        >
                                            删除
                                        </button>
                                        <button
                                            className="text-red-600 hover:text-red-900"
                                            onClick={() => actionEdit(item)}
                                        >
                                            编辑
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default App;
